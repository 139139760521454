@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#printabledivbox *{
    font-family: Work Sans;
}

#printabledivbox p{
    margin: 0px !important;
}

#printabledivbox td{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#printabledivbox th{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@media print {
    .no-print-border{
        border-bottom: 0px !important;
    }

    .hide-on-print{
        display:none !important;
    }

    #printabledivbox p{
        margin: 0px !important;
    }
    
    #printabledivbox td{
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
    #printabledivbox th{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
}